@font-face {
  font-family: Dela Gothic One;
  src: url("DelaGothicOne-Regular.a546fe1e.eot");
  src: local(Dela Gothic One Regular), local(DelaGothicOne-Regular), url("DelaGothicOne-Regular.a546fe1e.eot#iefix") format("embedded-opentype"), url("DelaGothicOne-Regular.390a9dd2.woff2") format("woff2"), url("DelaGothicOne-Regular.4193df9f.woff") format("woff"), url("DelaGothicOne-Regular.f64f053e.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*# sourceMappingURL=detailing.4ff1cbd5.css.map */
